interface IFreeAccountContentItem {
  title: string;
  description: string;
  link: string;
  linkText: string;
  icon: string;
}

const DOWNLOAD_URL = 'https://hilfe.idana.com/kb/guide/de/so-installieren-sie-idana-KMyDCGEIRH/Steps/307428';

const DEMO_URL = 'https://meetings.hubspot.com/melanie-achenbach/gemeinsamer-sales-kalender-demo';

export const freeAccountItems: IFreeAccountContentItem[] = [
  {
    title: 'Idana herunterladen',
    description:
      'Laden Sie die Vollversion von Idana herunter. Während der Testphase können Sie die Software im vollen Umfang testen.',
    link: DOWNLOAD_URL,
    linkText: 'Zum Download',
    icon: 'mdi-download-outline',
  },
  {
    title: 'Idana kaufen',
    description:
      'Sind Sie bereit mit Idana in Ihrer Praxis durchzustarten?\n\nWir unterstützen Sie vollumfänglich bei der Planung, technischen Integration und schulen Sie und Ihr Team.',
    link: '/order-subscription/licences',
    linkText: 'Jetzt kaufen',
    icon: 'mdi-credit-card-outline',
  },
  {
    title: 'Live-Demo buchen',
    description:
      'Möchten Sie sehen, wie Idana nach der Anbindung automatisch Daten in Ihre Praxissoftware überträgt?\n\nWir zeigen Ihnen, wie Idana in der Praxis funktioniert und beraten Sie ausführlich.',
    link: DEMO_URL,
    linkText: 'Live-Demo buchen',
    icon: 'mdi-phone-in-talk-outline',
  },
];
